import React from "react"
import { Link } from "react-router-dom"

function Profile() {
  return (
    <div className='profile'>
      <div className='profile__text'>
        <h2>I’m Thodoris Kallioras, a web developer skilled in React & Vue.</h2>
        <p>
          With a degree in computer science, I've honed my skills in JavaScript
          TypeScript and many more. Having navigated the dynamic environments of
          both large enterprises and startups during internships, I am now
          actively seeking roles as Web Developer. Let's create exceptional web
          experiences together!
        </p>
        <Link to='/contact' className='profile-link'>
          Contact me
        </Link>
      </div>
      <div className='profile__img'></div>
    </div>
  )
}

export default Profile
